
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

.bg { 
  /* The image used */
  /*background-image: url("https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");*/

  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.input-primary { 
  text-align: center;
  font-size: 3em;
  border: none ;
  font-weight: 100;
  background: transparent;
  width: 80%;
}

.input-secondary { 
  text-align: center;
  font-size: 1.5em;
  border: none ;
  font-weight: 100;
  background: transparent;
  width: 80%;
}

.list-group-item {
  user-select: none;
  border: transparent 1px solid;
  font-size: 0.8em;
}

.list-group input[type="checkbox"] {
  display: none;
}

.list-group input[type="checkbox"] + .list-group-item {
  cursor: pointer;
}

.list-group input[type="checkbox"]:checked + .list-group-item {
  background-color: rgb(230, 230, 230);
  /*color: #FFF;*/
}

.list-group input[type="checkbox"]:checked + .list-group-item:before {
  color: inherit;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .55rem .4rem;
  font-size: .8rem;
  line-height: .5;
  border-radius: .2rem;
}

.list-expertise {
  overflow-y: scroll; 
  height: 300px;
}

.nav-tabs > li {
  float:none;
  display:inline-block;
  zoom:1;
}

.nav-tabs {
  text-align:center;
}

.skill-checkbox {
  top: -1px !important;
  margin-right: 3px;
}

.img-preview {
  width: 250px !important;
  display:inline-block;
  position:relative;
  border:1px solid #ccc;
  margin-bottom:30px;

  -webkit-box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
  -moz-box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
  box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
}

.paypalbutton-container{
  width: 250px !important;
}

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.slick-slide img {
  display: inline !important;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 0px 0px 0 0 !important;
  border: none !important;
  border-top: 0px solid #aaa !important; 
  border-left: 0px solid #aaa !important; 
  border-right: 0px solid #aaa !important; 
  border-bottom: 1px solid #aaa !important; 
}

.react-tabs__tab-list {
  border-bottom: 0px solid #aaa !important; 
  margin: 0 0 10px !important; 
  padding: 0 !important; 
}

.languages{
  text-align: center;
  font-size: 1.5em;
  border: none ;
  font-weight: 100;
  background: transparent;
  width: 80%;
}

.checkbox-label {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 400;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  border-radius: 5px;
}

.checkbox-label:hover{
  cursor: pointer;
  -moz-box-shadow: inset 0 -1px 10px -1px #aaa;
  -webkit-box-shadow: inset 0 -1px 1px -1px #aaa;
  box-shadow: inset 0 -1px 1px -1px #aaa;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #E0E0E0;
  border-radius: 5px;
}

.checkbox-label-selected {
  background-color: #E0E0E0;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}


a {
  color: rgb(36, 36, 36);
}

a:hover {
  color: rgb(36, 36, 36);
}

.scroll-downs {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  
  width :34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: rgb(219, 219, 219);
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

.blog-post-image{
  border-radius: 10px;
}

.blog-post-image-sm{
  border-radius: 10px;
}

.avatar{
  width: 42px;
  height: 42px;
}

@media (max-width: 1024px) {
  
  .input-primary { 
    text-align: center;
    font-size: 1.5em;
    border: none ;
    font-weight: 100;
    background: transparent;
    width: 80%;
  }
  
  .input-secondary { 
    text-align: center;
    font-size: 1em;
    border: none ;
    font-weight: 100;
    background: transparent;
    width: 80%;
  }
}

.price {
  font-size: 2.5em;
}

.modal-header {
  border-bottom: none;
  padding: 35px 15px 0 15px;
  background-image: url(https://images.pexels.com/photos/3797/black-and-white-sport-fight-boxer.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500);
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
}

.text-shadow {
  text-shadow: 0px 0px 3px white;
}

.promo-input{
  background-color: #f7f7f7;
  border: none;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #6c757d;
}

.promo-input:focus{
  border-top: none;
  background-color: #f7f7f7;
  border-left: none;
  border-right: none;
  -webkit-box-shadow:inset 0px -3px 0px #ffc107;
  -moz-box-shadow:inset 0px -3px 0px #ffc107;
  box-shadow:inset 0px -3px 0px #ffc107;
}

.old-price {
  text-decoration: line-through;
  font-size: 0.5em;
}

.checkout-hr {
  margin-top: 1rem;
  margin-bottom: 0 !important;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

.modal-footer {
  background-color: #f7f7f7;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: center !important;
  padding: 0.7rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.subtitle{
  font-size: 1.5em;
}

.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #cccccc !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #cccccc !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.resumeImage{
  height: 380px;
}
